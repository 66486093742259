<template>

<div>

	<com-title text="Attendees" />

	<com-item route="Convention.Schedule.Events" text="Events" :count="$convention.count.schedule.events" />
	<com-item route="Convention.Schedule.Proposals" text="Proposals" :count="$convention.count.schedule.pending" />
	<com-item route="Convention.Schedule.Messages" text="Messages" :count="$convention.count.schedule.messages" />

	<com-title text="Convention" />

	<com-item route="Convention.Schedule.Contests" text="Contests" :count="$convention.count.schedule.contests" />
	<com-item route="Convention.Schedule.Types" text="Event Types" :count="$convention.count.schedule.types" />
	<com-item route="Convention.Schedule.Lobbies" text="Lobbies" :count="$convention.count.schedule.lobbies" />
	<com-item route="Convention.Schedule.Rooms" text="Rooms" :count="$convention.count.schedule.rooms" />
	<com-item route="Convention.Schedule.Tickets" text="Ticket Types" :count="$convention.count.schedule.tickets" />
	<com-item route="Convention.Schedule.Settings" text="Settings" />

</div>

</template>

<script>

export default {

	components: {
		'com-title': () => import('./../..//panel/common/Title'),
		'com-item': () => import('./../..//panel/common/Item')
	}

}

</script>

<style scoped>

</style>
